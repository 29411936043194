import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import { setErrorAction } from '../../helpers/Utils';
import axios from 'axios';
import { HEADERS, REQUEST } from '../../constants/defaultValues';

const startCreateRequest = createAsyncThunk('request/startCreateRequest', async ({ request, locale, token, form }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${REQUEST}/store`, request, { headers });

        if(res.data){
            form.resetForm()
            form.setStatus({ success: true })
        }

        dispatch(requestSlice.actions.setStage(res.data.stage));

        return res.data.status;
    } catch (error) {
        form.setStatus({ success: false })
        form.setSubmitting(false)
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateRequest = createAsyncThunk('request/startUpdateRequest', async ({ id, request, locale, token, form }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${REQUEST}/update/${id}`, request, { headers });

        if(res.data){
            form.setFieldValue('files', []);
            form.setStatus({ success: true });
        }

        dispatch(requestSlice.actions.setStage(res.data.stage));

        return res.data.status;
    } catch (error) {
        form.setStatus({ success: false });
        form.setSubmitting(false);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchRequest = createAsyncThunk('request/startFetchRequest', async ({ request, history, locale, token }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${REQUEST}/edit/${request}`, { headers });

        const data = res.data;

        const results = {
          edit: true,
          id: data.data.id,
          status_type_id: data.data.status_type_id,
          agent_id: data.data.agent_id,
          contact_id: data.data.contact_id,
          sale_or_rent: data.data.sale_or_rent,
          property_ids: data.data.property_ids,
          category_id: data.data.category_id,
          subcategories: data.data.subcategories,
          prefectures: data.data.prefectures,
          municipalities: data.data.municipalities,
          areas: data.data.areas,
          budget_from: data.data.budget_from,  
          budget_to: data.data.budget_to,  
          sq_meters_from: data.data.sq_meters_from,
          sq_meters_to: data.data.sq_meters_to,
          bedrooms: data.data.bedrooms,
          deliveries: data.data.deliveries,
          views: data.data.views,
          sea_accessibility: data.data.sea_accessibility,
          priority_type_id: data.data.priority_type_id,
          buy_priority_type_id: data.data.buy_priority_type_id,
          distance: data.data.distance,
          land_usage_from: data.data.land_usage_from,
          land_usage_to: data.data.land_usage_to,
          city_plan_type_id: data.data.city_plan_type_id,
          construction_year: data.data.construction_year ? data.data.construction_year : null,
          renovated_year: data.data.renovated_year ? data.data.renovated_year : null,
          pools: data.data.pools,
          parking: data.data.parking,
          level: data.data.level,
          floor: data.data.floor,
          energy_class: data.data.energy_class,
          floor_materials: data.data.floor_materials,
          heating_sources: data.data.heating_sources,
          heating_facilities: data.data.heating_facilities,
          electricity: data.data.electricity,
          water: data.data.water,
          buildable: data.data.buildable,
          extras: data.data.extras,
          contacts: data.data.contacts,
          sells: data.data.sells,
          arrival: data.data.arrival,
          bucket_id: data.data.bucket_id,
          income_date: data.data.income_date,
          last_offer_date: data.data.last_offer_date,
          creation_date: data.data.creation_date,
          source: data.data.source,
          loading: false,
          deleted: data.data.deleted
        };

        dispatch(requestSlice.actions.dataSelectSubcategory({ subcategory: data.subcategory }))
        dispatch(requestSlice.actions.dataSelectMunicipalities(data.municipalities))
        dispatch(requestSlice.actions.dataSelectAreas(data.areas))

        dispatch(requestSlice.actions.setStage(data.stage));

        return results;
    } catch (error) {
        history.push('/error');
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchRequests = createAsyncThunk('request/startFetchRequests', async ({ locale, token, filter, loading }, { rejectWithValue, dispatch }) => {
    try {
        dispatch(requestSlice.actions.actionFetchRequests(loading))
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${REQUEST}?
        pageSize=${filter.pageSize}
        &currentPage=${filter.currentPage}
        &orderBy=${filter.orderBy}
        &search=${filter.search}
        &assignee_type=${filter.searchFilter.assignee_type.length > 0 ? filter.searchFilter.assignee_type.join() : ""}
        &priority_type=${filter.searchFilter.priority_type.length > 0 ? filter.searchFilter.priority_type.join() : ""}
        &status_type=${filter.searchFilter.status_type.length > 0 ? filter.searchFilter.status_type.join() : ""}
        &stage_type=${filter.searchFilter.stage_type.length > 0 ? filter.searchFilter.stage_type.join() : ""}
        &transaction_type=${filter.searchFilter.transaction_type.length > 0 ? filter.searchFilter.transaction_type.join() : ""}
        &category_type=${filter.searchFilter.category_type.length > 0 ? filter.searchFilter.category_type.join() : ""}
        &subcategory_type=${filter.searchFilter.subcategory_type.length > 0 ? filter.searchFilter.subcategory_type.join() : ""}
        &prefecture_type=${filter.searchFilter.prefecture_type.length > 0 ? filter.searchFilter.prefecture_type.join() : ""}
        &municipality_type=${filter.searchFilter.municipality_type.length > 0 ? filter.searchFilter.municipality_type.join() : ""}
        &area_type=${filter.searchFilter.area_type.length > 0 ? filter.searchFilter.area_type.join() : ""}
        &bedrooms_type=${filter.searchFilter.bedrooms_type.length > 0 ? filter.searchFilter.bedrooms_type.join() : ""}
        &seaAccesibility_type=${filter.searchFilter.seaAccesibility_type.length > 0 ? filter.searchFilter.seaAccesibility_type.join() : ""}
        &nationality_type=${filter.searchFilter.nationality_type.length > 0 ? filter.searchFilter.nationality_type.join() : ""}
        &country_type=${filter.searchFilter.country_type.length > 0 ? filter.searchFilter.country_type.join() : ""}
    
        &fromDateCreate_type=${filter.searchFilter.fromDateCreate_type}
        &toDateCreate_type=${filter.searchFilter.toDateCreate_type}
        &fromBudget_type=${filter.searchFilter.fromBudget_type}
        &toBudget_type=${filter.searchFilter.toBudget_type}
        &fromSq_type=${filter.searchFilter.fromSq_type}
        &toSq_type=${filter.searchFilter.toSq_type}
        &propertyId_type=${filter.searchFilter.propertyId_type}
        `, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startDeleteRequest = createAsyncThunk('request/startDeleteRequest', async ({ locale, token, id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.delete(`${REQUEST}/destroy/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startActiveRequest = createAsyncThunk('request/startActiveRequest', async ({ locale, token, id, action }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.put(`${REQUEST}/active/${id}`, { action: action }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startRequestInfo = createAsyncThunk('request/startRequestInfo', async ({ request, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${REQUEST}/info/${request}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchDataFields = createAsyncThunk('request/startFetchDataFields', async ({ locale, token, ifEdit }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${REQUEST}/dataFields?lang=${locale}&edit=${ifEdit}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSearchFields = createAsyncThunk('request/startFetchSearchFields', async ({ locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${REQUEST}/searchFields?lang=${locale}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchSubcategory = createAsyncThunk('request/startFetchSubcategory', async ({ locale, token, ifEdit, category_id }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${REQUEST}/selectSubcategory?category_id=${category_id}&edit=${ifEdit}`, { headers });

        return res.data.subcategories;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchMunicipalities = createAsyncThunk('request/startFetchMunicipalities', async ({ locale, token, ifEdit, prefecture_ids }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${REQUEST}/getMunicipality`, { prefecture_ids: prefecture_ids, edit: ifEdit }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchAreas = createAsyncThunk('request/startFetchAreas', async ({ locale, token, ifEdit, prefecture_ids, municipality_ids }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${REQUEST}/getArea`, { prefecture_ids: prefecture_ids, municipality_ids: municipality_ids, edit: ifEdit }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startCopyRequest = createAsyncThunk('request/startCopyRequest', async ({ locale, token, id, requests }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${REQUEST}/copy/${id}`, { headers });

        let newRequests = {...requests};

        newRequests.data.unshift(res.data.request[0]);
        newRequests.totalItemCount = newRequests.totalItemCount + 1;

        let newArray = {
          data: newRequests.data,
          totalItemCount: newRequests.totalItemCount
        }

        dispatch(requestSlice.actions.fetchRequestsSuccess(newArray));

        return res.data.status;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startExcludePropertiesOffer = createAsyncThunk('request/startExcludePropertiesOffer', async ({ data, locale, token, index, properties }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        await axios.post(`${REQUEST}/exclude`, data, { headers });

        let props = JSON.parse(JSON.stringify(properties));
        props[index].exclude = !props[index].exclude;

        return props;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchPropMatching = createAsyncThunk('request/startFetchPropMatching', async ({ id, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.get(`${REQUEST}/fetchPropMatching/${id}`, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startOfferRequest = createAsyncThunk('request/startOfferRequest', async ({ locale, token, props, request_id, toggleModal }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${REQUEST}/offer`, {props: props, request_id: request_id}, { headers });

        if(res.data){
            toggleModal();
        }

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startPropertiesOfferRequest = createAsyncThunk('request/startPropertiesOfferRequest', async ({ locale, token, oldProps, props, request_id, toggleModal }, { rejectWithValue, dispatch }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${REQUEST}/propertiesOffer`, {props: props, request_id: request_id}, { headers });

        if(res.data){
            let prevProps = [...oldProps];

            if(res.data.properties.length > 0){
                let new_array = res.data.properties.concat(prevProps);
                dispatch(requestSlice.actions.setPropMatching(new_array));
            }

            toggleModal();
        }
        
        return res.data.status;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startFetchIndication = createAsyncThunk('request/startFetchIndication', async ({ id, locale, token, toggle }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${REQUEST}/fetchIndicationData`, {req_id: id}, { headers });

        return res.data;
    } catch (error) {
        toggle(true);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const startUpdateContactIndication = createAsyncThunk('request/startUpdateContactIndication', async ({ contact_id, name, surname, email, fathers_name, address, postal, phone, mobile, passport, vat, locale, token }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${REQUEST}/updateContactIndication`, {
            contact_id, 
            name, 
            surname, 
            email, 
            fathers_name, 
            address,
            postal, 
            phone, 
            mobile,
            passport,
            vat
        }, { headers });

        return res.data;
    } catch (error) {
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});

const sendIndication = createAsyncThunk('request/sendIndication', async ({ formData, locale, token, form, toggle }, { rejectWithValue }) => {
    try {
        const headers = HEADERS;
        headers['X-localization'] = locale;
        headers['Authorization'] = `Bearer ${token}`;

        const res = await axios.post(`${REQUEST}/sendIndication`, formData, { headers });

        if(res.data){
            toggle(true);
            form.resetForm();
            form.setStatus({ success: true });
        }

        return res.data;
    } catch (error) {
        form.setStatus({ success: false });
        form.setSubmitting(false);
        try {
            return rejectWithValue(setErrorAction(error.response.data.errors))
        } catch (err) {
            return rejectWithValue('Something went wrong. Please try again later.')
        }
    }
});


//Heading Functions
const setSelectedOrderOption = createAsyncThunk('request/setSelectedOrderOption', ( args ) => {
    return args;
});

const setSearch = createAsyncThunk('request/setSearch', ( args ) => {
    return args;
});

const setCurrentPage = createAsyncThunk('request/setCurrentPage', ( args ) => {
    return args;
});

const setSort = createAsyncThunk('request/setSort', ( args ) => {
    return args;
});

const initialState = {
    requests: {
        data: [],
        totalItemCount: 0
    },
    requestInfo: '',
    displayMode: "list",
    selectedPageSize: 10,
    selectedOrderOption: "",
    search: "",
    searchValue: "",
    searchFilterType: 1,
    stage: "",
    fields: [],
    searchFilter: [
        { translate: "general.assignee", id: "assignee_type", dataKey: "assignee_types", search: "", type: 1 },
        { translate: "general.priority", id: "priority_type", dataKey: "priority_types", search: "", type: 1 },
        { translate: "general.status", id: "status_type", dataKey: "status_types", search: "", type: 1 },
        { translate: "menu.stage-list", id: "stage_type", dataKey: "stage_types", search: "", type: 1 },
        { translate: "general.sale-rent", id: "transaction_type", dataKey: "transaction_types", search: "", type: 1 },
        { translate: "property.property-category", id: "category_type", dataKey: "category_types", search: "", type: 1 },
        { translate: "property.property-subcategory", id: "subcategory_type", dataKey: "subcategory_types", search: "", type: 1 },
        { translate: "general.prefectures", id: "prefecture_type", dataKey: "prefecture_types", search: "", type: 1 },
        { translate: "general.municipalities", id: "municipality_type", dataKey: "municipality_types", search: "", type: 1 },
        { translate: "general.areas", id: "area_type", dataKey: "area_types", search: "", type: 1 },
        { translate: "property.property-bedrooms", id: "bedrooms_type", dataKey: "bedrooms_types", search: "", type: 1 },
        { translate: "property.property-sea-accessibility", id: "seaAccesibility_type", dataKey: "seaAccesibility_types", search: "", type: 1 },
        { translate: "general.country", id: "country_type", dataKey: "country_types", search: "", type: 1 },
        { translate: "general.nationality", id: "nationality_type", dataKey: "nationality_types", search: "", type: 1 },

        { translate: "general.create-date", id: "dateCreate_type", dataKey: "", search: "", type: 1 },
        { translate: "general.budget", id: "budget_type", dataKey: "", search: "", type: 1 },
        { translate: "property.property-sq-meters", id: "sq_type", dataKey: "", search: "", type: 1 },
        { translate: "general.property_id", id: "propertyId_type", dataKey: "", search: "", type: 1 }
    ],
    searchFilterValues: {
        assignee_type: [],
        status_type: [],
        stage_type: [],
        priority_type: [],
        transaction_type: [],
        category_type: [],
        subcategory_type: [],
        prefecture_type: [],
        municipality_type: [],
        area_type: [],
        bedrooms_type: [],
        seaAccesibility_type: [],
        country_type: [],
        nationality_type: [],

        fromDateCreate_type: "",
        toDateCreate_type: "",
        fromBudget_type: "",
        toBudget_type: "",
        fromSq_type: "",
        toSq_type: "",
        propertyId_type: ""
    },
    currentPage: 1,
    requestData: {
        status_type_id: 3,
        agent_id: '',
        contact_id: '',
        sale_or_rent: 1,
        property_ids: '',
        category_id: '',
        subcategories: [],
        prefectures: [],
        municipalities: [],
        areas: [],
        budget_from: '',  
        budget_to: '',  
        sq_meters_from: '',
        sq_meters_to: '',
        bedrooms: '',
        deliveries: [],
        views: [],
        sea_accessibility: '',
        priority_type_id: '',
        buy_priority_type_id: '',
        distance: 0,
        land_usage_from: '',
        land_usage_to: '',
        city_plan_type_id: '',
        construction_year: null,
        renovated_year: null,
        pools: [],
        parking: '',
        level: '',
        floor: '',
        energy_class: '',
        floor_materials: [],
        heating_sources: [],
        heating_facilities: [],
        electricity: '',
        water: '',
        buildable: '',
        extras: [],
        contacts: [],
        sells: [{ id: '', property_id: '', price: '', fee: '' }],
        arrival: "",
        bucket_id: '',
        income_date: "",
        creation_date: "",
        last_offer_date: "",
        source: "",
        loading: false,
        deleted: 0
    },
    createRequest: '',
    updateRequest: '',
    deleteRequest: '',
    activeRequest: '',
    activeAccount: '',
    offerRequest: '',
    copyRequest: '',
    requestIndication: '',
    updateContactIndication: '',
    propMatching: [],
    dataFields: {
        status: [],
        agents: [],
        categories: [],
        prefectures: [],
        deliveries: [],
        sea_accessibilities: [],
        priorities: [],
        buy_priorities: [],
        plans: [],
        buildables: [],
        pools: [],
        energy_class: [],
        floor_materials: [],
        heating_sources: [],
        heating_facilities: [],
        extras: [],
        stages_types: [],
        views: [],
    },
    requestIndicationData: {
        request_id: '',
        properties: '',
        contact_id: '',
        name: '',
        surname: '',
        email: '',
        address: '',
        vat: '',
        passport: '',
        phone: '',
        mobile: '',
        postal: '',
        language: '',
        agreed_fee: '',
        appointment_date: new Date()
    },
    subcategory: [],
    municipalities: [],
    areas: [],
    loadingBtn: false,
    loading: true,
    loadingModal: false,
    loadingPropModal: true,
    loadingPropModalBtn: false,
    loadingPropMatchingModalBtn: false,
    loadingSearch: false,
    loadingBtnContactIndication: false,
    error: ''
}

export const requestSlice = createSlice({
    name: 'request',
    initialState,
    reducers: {
        fetchRequestsSuccess(state, { payload }) {
            return { ...state, loading: false, loadingSearch: false, requests: payload, error: '' };
        },
        clearRequest(state) {
            return { ...state, loadingBtn: false, loading: false, loadingSearch: false, loadingBtnContactIndication: false, requestData: initialState.requestData, stage: "", error: '' };
        },
        clearAlertRequest(state) {
            return { ...state, updateRequest: '', createRequest: '', deleteRequest: '', activeRequest: '', offerRequest: '', activeAccount: '', createRequestAction: '', updateRequestAction: '', copyRequest: '', requestIndication: '', updateContactIndication: '', error: '' };
        },
        clearRequestIndication(state) {
            return { ...state, loadingBtn: false, requestIndicationData: initialState.requestIndicationData, requestIndication: '', error: '' };
        },

        setDisplayMode(state, { payload }) {
            return { ...state, displayMode: payload };
        },
        setSelectedPageSize(state, { payload }) {
            return { ...state, selectedPageSize: payload, currentPage: 1 };
        },
        setFilter(state, { payload }) {
            return { ...state, searchFilterValues: payload };
        },
        setFilterType(state, { payload }) {
            return { ...state, searchFilterType: payload };
        },
        setFilterFieldSearch(state, { payload }) {
            return { ...state, fields: payload.dataValues, searchFilter: payload.values };
        },
        setSearchValue(state, { payload }) {
            return { ...state, searchValue: payload }
        },
        setStage(state, { payload }) {
            return { ...state, stage: payload };
        },
        setPropMatching(state, { payload }) {
            return { ...state, propMatching: payload };
        },
        dataSelectAreas(state, { payload }) {
            return { ...state, areas: payload, error: '' };
        },
        dataSelectSubcategory(state, { payload }) {
            return { ...state, subcategory: payload.subcategory, error: '' };
        },
        dataSelectMunicipalities(state, { payload }) {
            return { ...state, municipalities: payload, error: '' };
        },
        actionFetchRequests(state, { payload }) {
            return { ...state, loading: payload ? true : false, loadingSearch: true, error: '' };
        }
    },
    extraReducers: {
        [startCreateRequest.pending]: (state) => {
            return { ...state, loadingBtn: true, error: '' };
        },
        [startCreateRequest.fulfilled]: (state, { payload }) => {
            return { ...state, loadingBtn: false, createRequest: payload, error: '' };
        },
        [startCreateRequest.rejected]: (state, { payload }) => {
            return { ...state, loadingBtn: false, createRequest: '', error: payload };
        },

        [startUpdateRequest.pending]: (state) => {
            return { ...state, loadingBtn: true, error: '' };
        },
        [startUpdateRequest.fulfilled]: (state, { payload }) => {
            return { ...state, loadingBtn: false, updateRequest: payload, error: '' };
        },
        [startUpdateRequest.rejected]: (state, { payload }) => {
            return { ...state, loadingBtn: false, updateRequest: '', error: payload };
        },
        
        [startFetchRequest.pending]: (state) => {
            return { ...state, loading: true, error: '' };
        },
        [startFetchRequest.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false, requestData: payload, error: '' };
        },
        [startFetchRequest.rejected]: (state, { payload }) => {
            return { ...state, loading: false, requestData: initialState.requestData, error: payload };
        },

    
        [startFetchRequests.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false, loadingSearch: false, requests: payload, error: '' };
        },
        [startFetchRequests.rejected]: (state, { payload }) => {
            return { ...state, loading: false, loadingSearch: false, requests: initialState.requests, error: payload };
        },

        [startDeleteRequest.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false, deleteRequest: payload, error: '' };
        },
        [startDeleteRequest.rejected]: (state, { payload }) => {
            return { ...state, loading: false, deleteRequest: '', error: payload };
        },

        [startActiveRequest.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false, activeRequest: payload, error: '' };
        },
        [startActiveRequest.rejected]: (state, { payload }) => {
            return { ...state, loading: false, activeRequest: '', error: payload };
        },

        [startRequestInfo.pending]: (state) => {
            return { ...state, loadingModal: true, error: '' };
        },
        [startRequestInfo.fulfilled]: (state, { payload }) => {
            return { ...state, loadingModal: false, requestInfo: payload, error: '' };
        },
        [startRequestInfo.rejected]: (state, { payload }) => {
            return { ...state, loadingModal: false, requestInfo: '', error: payload };
        },

        [startFetchDataFields.pending]: (state) => {
            return { ...state, loading: true, error: '' };
        },
        [startFetchDataFields.fulfilled]: (state, { payload }) => {
            return { ...state, dataFields: payload, error: '' };
        },
        [startFetchDataFields.rejected]: (state, { payload }) => {
            return { ...state, loading: false, dataFields: initialState.dataFields, error: payload };
        },

        [startFetchSearchFields.pending]: (state) => {
            return { ...state, loading: true, error: '' };
        },
        [startFetchSearchFields.fulfilled]: (state, { payload }) => {
            return { ...state, fields: payload, error: '' };
        },
        [startFetchSearchFields.rejected]: (state, { payload }) => {
            return { ...state, loading: false, fields: initialState.fields, error: payload };
        },

        
        [startFetchSubcategory.fulfilled]: (state, { payload }) => {
            return { ...state, subcategory: payload, error: '' };
        },
        [startFetchSubcategory.rejected]: (state, { payload }) => {
            return { ...state, subcategory: initialState.subcategory, error: payload };
        },

        [startFetchMunicipalities.fulfilled]: (state, { payload }) => {
            return { ...state, municipalities: payload, error: '' };
        },
        [startFetchMunicipalities.rejected]: (state, { payload }) => {
            return { ...state, municipalities: initialState.municipalities, error: payload };
        },

        [startFetchAreas.fulfilled]: (state, { payload }) => {
            return { ...state, areas: payload, error: '' };
        },
        [startFetchAreas.rejected]: (state, { payload }) => {
            return { ...state, areas: initialState.areas, error: payload };
        },

        [startCopyRequest.fulfilled]: (state, { payload }) => {
            return { ...state, loading: false, copyRequest: payload, error: '' };
        },
        [startCopyRequest.rejected]: (state, { payload }) => {
            return { ...state, loading: false, copyRequest: '', error: payload };
        },

        [startExcludePropertiesOffer.fulfilled]: (state, { payload }) => {
            return { ...state, propMatching: payload };
        },
        [startExcludePropertiesOffer.rejected]: (state, { payload }) => {
            return { ...state, propMatching: [], error: payload };
        },

        [startFetchPropMatching.pending]: (state) => {
            return { ...state, loadingPropModal: true, error: '' };
        },
        [startFetchPropMatching.fulfilled]: (state, { payload }) => {
            return { ...state, loadingPropModal: false, propMatching: payload, error: '' };
        },
        [startFetchPropMatching.rejected]: (state, { payload }) => {
            return { ...state, loadingPropModal: false, propMatching: [], error: payload };
        },

        [startOfferRequest.pending]: (state) => {
            return { ...state, loadingPropModalBtn: true, error: '' };
        },
        [startOfferRequest.fulfilled]: (state, { payload }) => {
            return { ...state, loadingPropModalBtn: false, offerRequest: payload, error: '' };
        },
        [startOfferRequest.rejected]: (state, { payload }) => {
            return { ...state, loadingPropModalBtn: false, offerRequest: '', error: payload };
        },

        [startPropertiesOfferRequest.pending]: (state) => {
            return { ...state, loadingPropModalBtn: true, error: '' };
        },
        [startPropertiesOfferRequest.fulfilled]: (state, { payload }) => {
            return { ...state, loadingPropModalBtn: false, offerRequest: payload, error: '' };
        },
        [startPropertiesOfferRequest.rejected]: (state, { payload }) => {
            return { ...state, loadingPropModalBtn: false, offerRequest: '', error: payload };
        },

        [startFetchIndication.pending]: (state) => {
            return { ...state, loadingModal: true, error: '' };
        },
        [startFetchIndication.fulfilled]: (state, { payload }) => {
            let new_requestIndicationData = {...payload};
            new_requestIndicationData.appointment_date = new Date();
            return { ...state, loadingModal: false, requestIndicationData: new_requestIndicationData, error: '' };
        },
        [startFetchIndication.rejected]: (state, { payload }) => {
            return { ...state, loadingModal: false, requestIndicationData: initialState.requestIndicationData, error: payload };
        },

        [startUpdateContactIndication.pending]: (state) => {
            return { ...state, loadingBtnContactIndication: true, error: '' };
        },
        [startUpdateContactIndication.fulfilled]: (state, { payload }) => {
            return { ...state, loadingBtnContactIndication: false, updateContactIndication: payload, error: '' };
        },
        [startUpdateContactIndication.rejected]: (state, { payload }) => {
            return { ...state, loadingBtnContactIndication: false, updateContactIndication: '', error: payload };
        },

        [sendIndication.pending]: (state) => {
            return { ...state, loadingBtn: true, error: '' };
        },
        [sendIndication.fulfilled]: (state, { payload }) => {
            return { ...state, loadingBtn: false, requestIndication: payload, error: '' };
        },
        [sendIndication.rejected]: (state, { payload }) => {
            return { ...state, loadingBtn: false, requestIndication: '', error: payload };
        },

      

        //Heading Functions
        [setSelectedOrderOption.fulfilled]: (state, { payload }) => {
            return { ...state, selectedOrderOption: payload, currentPage: 1 };
        },
        [setSearch.fulfilled]: (state, { payload }) => {
            return { ...state, search: payload, currentPage: 1 }
        },
        [setCurrentPage.fulfilled]: (state, { payload }) => {
            return { ...state, currentPage: payload }
        },
        [setSort.fulfilled]: (state, { payload }) => {
            return { ...state, sort: payload }
        }
    },
});


export const requestActions = {
    ...requestSlice.actions,
    startCreateRequest,
    startUpdateRequest,
    startFetchRequest,
    startFetchRequests,
    startDeleteRequest,
    startActiveRequest,
    startRequestInfo,
    startFetchDataFields,
    startFetchSearchFields,
    startFetchSubcategory,
    startFetchMunicipalities,
    startFetchAreas,
    startCopyRequest,
    startExcludePropertiesOffer,
    startFetchPropMatching,
    startOfferRequest,
    startPropertiesOfferRequest,
    startFetchIndication,
    startUpdateContactIndication,
    sendIndication,
    setSelectedOrderOption,
    setSearch,
    setCurrentPage,
    setSort
}
export default requestSlice.reducer